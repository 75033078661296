import CryptoJS from 'crypto-js'


export function encryptText(text) {
  return CryptoJS.AES.encrypt(text, process.env.VUE_APP_CRYPTO_KEY).toString()
}

export function decryptText(encryptedText) {
  return CryptoJS.AES.decrypt(encryptedText, process.env.VUE_APP_CRYPTO_KEY).toString(CryptoJS.enc.Utf8)
}
