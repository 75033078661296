import httpClient from "@/services/http.service"
import router from "@/router"


const conferenceAuthorService = {
    getPpsInfo() {
        return httpClient.get(`pps/pps-data/get-pps-info`)
    },
    getConference() {
        return httpClient.get(`conference/conference/index`)
    },
    getSpUniversity() {
        return httpClient.get(`conference/conference/get-university`)
    },
    getConferenceUser() {
        return httpClient.get(`conference/conference-user/index`)
    },
    getConferenceSection(conference_id) {
        return httpClient.get(`conference/conference-section/index?conference_id=${conference_id}`)
    },
    postConferenceUser(data) {
        return httpClient.post(`conference/conference-user/create`, data)
    },
    postConferenceArticle(data) {
        return httpClient.post(`conference/conference-article/send-article`, data)
    },
    getConferenceArticle(status = 0) {
        return httpClient.get(`conference/conference-article/get-article?status=${status}`)
    },
    getAuthorsByLastName(last_name) {
        return httpClient.get(`pps/pps-data/get-authors-by-last-name` + '?last_name=' + last_name)
    },
    getConferenceComment(conference_article_id) {
        return httpClient.get(`conference/conference-article-comment/get-article-comments` + '?conference_article_id=' + conference_article_id)
    },

    putConferenceArticle(data, updateArticleId) {
        console.log(Object.fromEntries(data), 'hello from data')

        return httpClient.put(`conference/conference-article/update-article`+ '?id='+ updateArticleId, data)
    },
}

export default conferenceAuthorService
