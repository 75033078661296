<template>

  <router-view/>

  <FlashMessage group="message"></FlashMessage>
  <Toast position="bottom-right"/>

</template>

<script>
import authService from "@/services/auth.service";
import {deleteAllCookies, getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: 'App',
    components: {},


    mounted() {
      if (getCookie('PASSWORD_CHANGED')){
        localStorage.clear()
        deleteAllCookies()
      }

      if (!getCookie('FRONT_UP_APP')){
        deleteAllCookies()
      }
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  a.p-menubar-button {
    display: none !important;
  }

  ul.p-submenu-list {
    right: -8px;
    top: 53px;
  }

  @media screen and (max-width: 960px) {
    .accountMenu > a.p-menubar-button {
      display: block !important;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      top: 50px;
      left: -10px;
      width: 63px;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
      position: absolute;
      right: 0px;
      top: 50px;
      width: 245px;
      /* background-color: #212129; */
    }
  }

  .w100p {
    width: 100%;
  }

</style>
