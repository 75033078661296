import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home.vue'
import Main from '@/views/Main.vue'
import authService from '@/services/auth.service'
import httpClient from "@/services/http.service";
import axios from "axios";
import {getCookie} from "@/utils/helpers/cookies.helpers";
import {decryptCheckRole, getDecryptedRoles} from "@/utils/helpers/decryptedRoles.helpers";

const routes = [
    {
        path: '/',
        name: 'Default',
        redirect: '/home',
        component: () => import('@/layouts/DefaultLayout.vue'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: Main,
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    viceRector: true,
                    commission: true,
                    student: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    shareholder: true
                },
            },
            {
                path: 'restrict',
                name: 'restrict',

                component: () => import('@/views/Restrict.vue'),
            },
            {
                path: 'vacancies',
                name: 'vacancies',
                component: Home,
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    viceRector: true,
                    commission: true,
                    shareholder: true,
                },
            },
            {
                path: 'profile',
                name: 'profile',
                meta: {
                    admin: true,
                    candidate: true,
                    student: true
                },
                component: () => import('@/components/Profile.vue')
            },
            {
                path: 'my-contracts',
                name: 'my-contracts',
                meta: {
                    admin: true,
                    candidate: true,
                    viceRector: true,
                    departmentHead: true,
                },
                component: () => import('@/views/MyContracts.vue')
            },
            {
                path: 'academic-calendar',
                name: 'academic-calendar',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                    student: true,
                },
                component: () => import('@/views/AcademicCalendar.vue')
            },
            {
                path: 'main',
                name: 'main',
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/views/user-personal/Main.vue')
            },
            {
                path: 'news',
                name: 'news',
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/views/News.vue')
            },
            {
                path: 'faq',
                name: 'faq',
                meta: {
                    admin: true,
                    candidate: true,
                    dean: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/views/FrequentlyAskedQuestion.vue')
            },
            {
                path: 'articles-formation-requirements',
                name: 'articles-formation-requirements',
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/views/news/ArticlesFormationRequirements.vue'),
            },
            {
                path: 'enrollee-eds',
                name: 'enrollee-eds',
                meta: {
                    admin: true,
                    enrollee: true,
                },
                component: () => import('@/components/signature/EnrolleeSignature.vue')
            },
            {
                path: 'dean-eds',
                name: 'dean-eds',
                meta: {
                    admin: true,
                    dean: true,
                    viceRector: true,
                },
                component: () => import('@/components/signature/DeanSignature.vue')
            },
            {
                path: 'tech-secretary-eds',
                name: 'tech-secretary-eds',
                meta: {
                    admin: true,
                    tehSecretary: true,
                },
                component: () => import('@/components/signature/TechSecretarySignature.vue')
            },
            {
                path: 'student-sponsor-eds',
                name: 'student-sponsor-eds',
                meta: {
                    admin: true,
                    studentSponsor: true
                },
                component: () => import('@/components/signature/StudentSponsorSignature.vue')
            },
            {
                path: 'signature-info',
                name: 'signature-info',
                meta: {
                    admin: true,
                    viceRector: true,
                },
                component: () => import('@/components/signature/SignatureInfo.vue')
            },
            {
                path: 'education-field',
                name: 'education-field',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/education-programme/EducationField.vue')
            },
            {
                path: 'education-direction',
                name: 'education-direction',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/education-programme/EducationDirection.vue')
            },
            {
                path: 'education-program-result',
                name: 'education-program-result',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    educationprogramSupervisor: true,
                },
                component: () => import('@/components/education-programme/EducationProgramResult.vue')
            },
            {
                path: 'education-curriculum',
                name: 'education-curriculum',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    shareholder: true,
                },
                component: () => import('@/components/education-programme/EducationCurriculum.vue')
            },
            {
                path: 'education-curriculum-info',
                name: 'education-curriculum-info',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/education-programme/EducationCurriculumInfo.vue')
            },
            {
                path: 'passport-of-education-program',
                name: 'passport-of-education-program',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/education-programme/PassportEducationProgram.vue')
            },
            {
                path: 'abiturient-orders',
                name: 'abiturient-orders',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/abiturient/AbiturientOrders.vue')
            },
            {
                path: 'abiturient-list',
                name: 'abiturient-list',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/abiturient/AbiturientList.vue')
            },
            {
                path: 'test',
                name: 'test',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/Test.vue')
            },
            {
                path: 'syllabus-template',
                name: 'syllabus-template',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                    qualityCenterMain: true,
                },
                component: () => import('@/components/syllabus/SyllabusTemplate.vue')
            },
            {
                path: 'syllabus-template-content',
                name: 'syllabus-template-content',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                    qualityCenterMain: true,
                },
                component: () => import('@/components/syllabus/SyllabusTemplateContent.vue')
            },
            {
                path: 'syllabus-document-history',
                name: 'syllabus-document-history',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                    qualityCenterMain: true,
                },
                component: () => import('@/components/syllabus/SyllabusDocumentHistory.vue')
            },
            {
                path: 'syllabus-literature',
                name: 'syllabus-literature',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                    qualityCenterMain: true,
                },
                component: () => import('@/components/syllabus/SyllabusLiterature.vue')
            },
            {
                path: 'syllabus-template-content-old',
                name: 'syllabus-template-content-old',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/syllabus/SyllabusTemplateContentOld.vue')
            },
            {
                path: 'syllabus-literature-old',
                name: 'syllabus-literature-old',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/syllabus/SyllabusLiteratureOld.vue')
            },
            {
                path: 'syllabus-list',
                name: 'syllabus-list',
                meta: {
                    admin: true,
                    departmentHead: true,
                    dean: true
                },
                component: () => import('@/components/syllabus/SyllabusList.vue')
            },
            {
                path: 'education-disciplines-list',
                name: 'education-disciplines-list',
                meta: {
                    admin: true,
                    shareholder: true,
                },
                component: () => import('@/components/admin/EducationDisciplinesList.vue')
            },
            {
                path: 'change-password',
                name: 'change-password',
                meta: {
                    admin: true,
                    enrollee: true,
                },
                component: () => import('@/components/ChangePassword.vue')
            },

            {
                path: 'goso-templates',
                name: 'goso-templates',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/GosoTemplates.vue')
            },

            {
                path: 'decree-goso-templates',
                name: 'decree-goso-templates',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/DecreeGosoTemplates.vue')
            },
            {
                path: 'goso-templates-view',
                name: 'goso-templates-view',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/education-programme/GosoTemplatesView.vue')
            },
            {
                path: 'education-module',
                name: 'education-module',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/components/education-programme/EducationModule.vue')
            },
            {
                path: 'education-discipline',
                name: 'education-discipline',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    HRHead: true,
                    davAssistant: true,
                    educationprogramSupervisor: true,
                },
                component: () => import('@/components/education-programme/EducationDiscipline.vue')
            },

            {
                path: 'formation-education-program',
                name: 'formation-education-program',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    departmentHead: true,
                    educationprogramSupervisor: true
                },
                component: () => import('@/components/education-programme/FormationEducationProgram.vue')
            },

            {
                path: 'minor-formation-education-program',
                name: 'minor-formation-education-program',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    departmentHead: true,
                    educationprogramSupervisor: true
                },
                component: () => import('@/views/education-programme/MinorFormationEducationProgram.vue')
            },
            {
                path: 'credit-time-norm-template',
                name: 'credit-time-norm-template',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/education-programme/CreditTimeNormTemplate.vue')
            },
            {
                path: 'teaching-load-old',
                name: 'teaching-load-old',
                meta: {
                    admin: true
                },
                component: () => import('@/components/education-programme/TeachingLoad.vue')
            },
            {
                path: 'curriculum-disciplines',
                name: 'curriculum-disciplines',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/CurriculumDisciplines.vue')
            },
            {
                path: 'curriculum-disciplines-new',
                name: 'curriculum-disciplines-new',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/CurriculumDisciplinesNew.vue')
            },
            {
                path: 'selectable-flows',
                name: 'selectable-flows',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/selectable-course/SelectableFlows.vue')
            },
            {
                path: 'selectable-groups',
                name: 'selectable-groups',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/selectable-course/SelectableGroups.vue')
            },
            {
                path: 'language-center-disciplines',
                name: 'language-center-disciplines',
                meta: {
                    admin: false,
                    student: false,
                    departmentHead: false,
                    officeRegistration: false,
                    officeRegistrationHead: false,
                },
                component: () => import('@/components/education-programme/curriculum/selectable-course/SelectableDisciplines.vue')
            },
            {
                path: 'selectable-groups-for-discipline',
                name: 'selectable-groups-for-discipline',
                meta: {
                    admin: false,
                    student: false,
                    departmentHead: false,
                    officeRegistration: false,
                    officeRegistrationHead: false,
                },
                component: () => import('@/components/education-programme/curriculum/selectable-course/SelectableGroupsForDiscipline.vue')
            },
            {
                path: 'binding-to-curriculum',
                name: 'binding-to-curriculum',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/BindingToCurriculum.vue')
            },
            {
                path: 'binding-to-curriculum-new',
                name: 'binding-to-curriculum-new',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/BindingToCurriculumNew.vue')
            },
            {
                path: 'publish-to-moodle',
                name: 'publish-to-moodle',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/PublishToMoodle.vue')
            },
            {
                path: 'bind-selectable-groups',
                name: 'bind-selectable-groups',
                meta: {
                    admin: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/components/education-programme/curriculum/BindSelectableGroups.vue')
            },
            {
                path: 'old-moodle-summer-ratings',
                name: 'old-moodle-summer-ratings',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                },
                component: () => import('@/views/old-moodle-ratings/OldSummerRatings.vue')
            },
            {
                path: 'departments',
                name: 'departments',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/admin/DepartmentsForm.vue')
            },
            {
                path: 'list-of-vacancies',
                name: 'list-of-vacancies',
                meta: {
                    admin: true,
                    departmentHead: true,
                    HRHead: true,
                },
                component: () => import('@/components/vacancy/Vacancies.vue')
            },
            {
                path: 'department-head-vacancies',
                name: 'department-head-vacancies',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/components/vacancy/DepartmentHeadVacancy.vue')
            },
            {
                path: 'vacancy-applications',
                name: 'vacancy-applications',
                meta: {
                    admin: true,
                    departmentHead: true,
                    HRHead: true,
                },
                component: () => import('@/components/vacancy/VacancyApplications.vue')
            },
            {
                path: 'commission-score',
                name: 'commission-score',
                meta: {
                    admin: true,
                    HRHead: true,
                    dean: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/components/vacancy/CommissionCandidateCriterionScore.vue')
            },
            {
                path: 'student-report',
                name: 'student-report',
                meta: {
                    admin: true,
                    student: true,
                },
                component: () => import('@/components/student/StudentReport.vue')
            },
            {
                path: 'student-attestation',
                name: 'student-attestation',
                meta: {
                    admin: true,
                    student: true,
                    studentNoAccess: false,
                    departmentHead: true,
                    dean: true,
                },
                component: () => import('@/components/student/StudentAttestation.vue')
            },
            {
                path: 'student-current-attestation',
                name: 'student-current-attestation',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/StudentCurrentAttestation.vue')
            },
            {
                path: 'student-transcript',
                name: 'student-transcript',
                meta: {
                    admin: true,
                    dean: true,
                    rector: true,
                    student: true,
                    studentNoAccess: false,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    departmentHead: true,
                    graduateWorkChairman: true,
                    graduateWorkCommissionMember: true,
                    graduateWorkTehSecretary: true,
                },
                component: () => import('@/components/student/StudentTranscript.vue')
            },
            {
                path: 'students',
                name: 'students',
                meta: {
                    admin: true,
                    departmentHead: true,
                    dean: true,
                    studentsSupport: true,
                    lawyer: true,
                    graduateWorkTehSecretary: true
                },
                component: () => import('@/components/admin/Students.vue')
            },
            {
                path: 'students-list',
                name: 'students-list',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/StudentsList.vue')
            },
            {
                path: 'complaints-list',
                name: 'complaints-list',
                meta: {
                    admin: true,
                    inspectorSupport: true
                },
                component: () => import('@/components/admin/ComplaintsList.vue')
            },
            {
                path: 'written-exam-courses-list',
                name: 'written-exam-courses-list',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/WrittenExamCoursesList.vue')
            },
            {
              path: 'students-for-stipend',
                name: 'students-for-stipend',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: ()=> import('@/components/doc-orders/StudentsForStipend.vue')
            },
            {
                path: 'test-exam-courses-list',
                name: 'test-exam-courses-list',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/TestExamCoursesList.vue')
            },
            {
                path: 'change-student-course',
                name: 'change-student-course',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/components/admin/ChangeStudentCourse.vue')
            },
            {
                path: 'add-to-lang-course',
                name: 'add-to-lang-course',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/components/admin/AddToLangCourse.vue')
            },
            {
                path: 'choose-students-language-level',
                name: 'choose-students-language-level',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/views/language-center/ChooseStudentsLanguageLevel.vue')
            },
            {
                path: 'unite-courses',
                name: 'unite-courses',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/UniteCourses.vue')
            },
            {
                path: 'consolidated-statement',
                name: 'consolidated-statement',
                meta: {
                    admin: true,
                    dean: true,
                    departmentHead: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    rector: true,
                },
                component: () => import('@/components/admin/ConsolidatedStatement.vue')
            },
            {
                path: 'consolidated-statement-by-group',
                name: 'consolidated-statement-by-group',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/ConsolidatedStatementByGroup.vue')
            },
            {
                path: 'my-courses',
                name: 'my-courses',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/teacher/MyCourses.vue')
            },
            {
                path: 'my-load-calculation',
                name: 'my-load-calculation',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/views/teacher/teaching-load/TeacherLoadCalculation.vue')
            },
            {
                path: 'journal-rating',
                name: 'journal-rating',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/teacher/JournalRating.vue')
            },
            {
                path: 'final-exam',
                name: 'final-exam',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/teacher/FinalExam.vue')
            },
            {
                path: 'total-grade',
                name: 'total-grade',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/teacher/TotalGrade.vue')
            },
            {
                path: 'attendance-report',
                name: 'attendance-report',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/teacher/AttendanceReport.vue')
            },
            {
                path: 'exam/:id',
                name: 'exam',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/admin/Exam.vue')
            },
            {
                path: 'user',
                name: 'user',
                meta: {
                    admin: true,
                    departmentHead: true,
                    HRHead: true,
                    commission: true,
                    viceRector: true,
                },
                component: () => import('@/views/UserInfo.vue')
            },
            {
                path: 'teacher-info',
                name: 'teacher-info',
                meta: {
                    admin: true,
                    student: true,
                    departmentHead: true,
                },
                component: () => import('@/views/TeacherInfo.vue')
            },
            {
                path: 'graduate-work-calendar',
                name: 'graduate-work-calendar',
                meta: {
                    admin: true,
                    dean: true,

                },
                component: () => import('@/components/graduate-work/dean/GraduateWorkCalendar.vue')
            },
            {
                path: 'graduate-work-theme',
                name: 'graduate-work-theme',
                meta: {
                    admin: true,
                    teacher: true,

                },
                component: () => import('@/components/graduate-work/teacher/GraduateWorkTheme.vue')
            },
            {
                path: 'graduate-work-temp',
                name: 'graduate-work-temp',
                meta: {
                    admin: true,
                    departmentHead: true

                },
                component: () => import('@/components/graduate-work/departmentHead/GraduateWorkTemp.vue')
            },
            {
                path: 'graduate-work-exam',
                name: 'graduate-work-exam',
                meta: {
                    admin: true,
                    departmentHead: true

                },
                component: () => import('@/views/graduate-work/GraduateWorkExam.vue')
            },
            {
                path: 'graduate-work-score',
                name: 'graduate-work-score',
                meta: {
                    admin: true,
                    departmentHead: true,
                    graduateWorkChairman: true,
                    graduateWorkTehSecretary: true,
                    graduateWorkCommissionMember: true,
                },
                component: () => import('@/views/graduate-work/GraduateWorkScore.vue')
            },
            {
                path: 'graduate-work-students-list',
                name: 'graduate-work-students-list',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/graduate-work/teacher/GraduateWorkStudentsList.vue')
            },
            {
                path: 'graduate-work',
                name: 'graduate-work',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    commission: true,
                    departmentHead: true,
                },
                component: () => import('@/components/graduate-work/teacher/GraduateWork.vue')
            },
            {
                path: 'graduate-data',
                name: 'graduate-data',
                meta: {
                    admin: true,
                    HRHead: true,
                    davAssistant: true,
                    officeRegistration: true,
                },
                component: () => import('@/views/graduate-work/GraduateData.vue')
            },
            {
                path: 'active-directory',
                name: 'active-directory',
                meta: {
                    admin: true,
                    HRHead: true,
                },
                component: () => import('@/components/active-directory/ActiveDirectory.vue')
            },
            {
                path: 'antiplagiat',
                name: 'antiplagiat',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/admin/Antiplagiat.vue')
            },
            {
                path: 'complaint-form',
                name: 'complaint-form',
                meta: {
                    admin: true,
                    student: true
                },
                component: () => import('@/components/complaint/СomplaintForm.vue')
            },
            {
                path: 'graduate-work-topic',
                name: 'graduate-work-topic',
                meta: {
                    admin: true,
                    student: true
                },
                component: () => import('@/components/graduate-work/student/GraduateWorkTopic.vue')
            },
            {
                path: 'upload-graduate-work',
                name: 'upload-graduate-work',
                meta: {
                    admin: true,
                    student: true
                },
                component: () => import('@/components/graduate-work/student/UploadGraduateWork.vue')
            },
            {
                path: 'selection-supervisors',
                name: 'selection-supervisors',
                meta: {
                    admin: true,
                },
                component: () => import('@/components/graduate-work/departmentHead/SelectionSupervisors.vue')
            },
            {
                path: 'load-calculation',
                name: 'load-calculation',
                meta: {
                    admin: true,
                    departmentHead: true
                },
                component: () => import('@/components/admin/LoadCalculation.vue')
            },
            {
                path: 'individual-pps-teaching-load',
                name: 'individual-pps-teaching-load',
                meta: {
                    admin: true,
                    departmentHead: true
                },
                component: () => import('@/views/department-head/teaching-load/IndividualPpsTeachingLoad.vue')
            },
            {
                path: 'formed-load-calculation',
                name: 'formed-load-calculation',
                meta: {
                    admin: true,
                    departmentHead: true
                },
                component: () => import('@/components/admin/FormedLoadCalculation.vue')
            },
            {
                path: 'powerbi-analysis',
                name: 'powerbi-analysis',
                meta: {
                    admin: true,
                    candidate: true,
                    rector: true,
                    viceRector: true,
                    dean: true,
                    HRHead: true,
                    davAssistant: true,
                    departmentHead: true,
                    scienceManager: true,
                    officeRegistration: true,
                    officeRegistrationHead: true,
                    qualityCenter: true,
                    qualityCenterMain: true,
                    shareholder: true,
                },
                component: () => import('@/components/analysis-page/ShowAnalysis.vue')
            },
            {
                path: 'primevue-student-attendance',
                name: 'primevue-student-attendance',
                meta: {
                    admin: true,
                    candidate: false,
                    rector: false,
                    viceRector: false,
                    dean: false,
                    departmentHead: false,
                    scienceManager: false,
                    officeRegistration: false,
                    officeRegistrationHead: false
                },
                component: () => import('@/components/analysis-page/PrimeVueAnalysis.vue')
            },
            {
                path: 'doc-orders',
                name: 'doc-orders',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/doc-orders/DocOrders.vue')
            },
            {
                path: 'doc-orders-students',
                name: 'doc-orders-students',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/doc-orders/DocOrdersStudents.vue')
            },
            {
                path: 'education-program-change-students',
                name: 'education-program-change-students',
                meta: {
                    admin: true,
                    lawyer: true,
                },
                component: () => import('@/views/lawyer/EducationProgramChangeStudents.vue')
            },
            {
                path: 'transfer-courses',
                name: 'transfer-courses',
                meta: {
                    admin: true,
                    departmentHead: true,
                },
                component: () => import('@/components/admin/TransferCourses.vue')
            },
            {
                path: 'education-courses-list',
                name: 'education-courses-list',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/education-programme/EducationCoursesList.vue')
            },
            {
                path: 'academic-debt',
                name: 'academic-debt',
                component: () => import('@/views/academic-debt/AcademicDebt.vue'),
                children: [
                    {
                        path: 'office-registration',
                        name: 'academic-debt-service-office-registration',
                        component: () => import('@/views/academic-debt/AcademicDebtOfficeRegistration.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'department-head',
                        name: 'academic-debt-service-department-head',
                        component: () => import('@/views/academic-debt/AcademicDebtDepartmentHead.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'student',
                        name: 'academic-debt-service-student',
                        component: () => import('@/views/academic-debt/AcademicDebtStudent.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                    {
                        path: 'courses',
                        name: 'academic-debt-service-courses',
                        component: () => import('@/views/academic-debt/AcademicDebtCourses.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                ]
            },
            {
                path: 'conference',
                name: 'conference',
                component: () => import('@/views/conference/Conference.vue'),
                children: [
                    {
                        path: 'conference-author',
                        name: 'conference-author',
                        component: () => import('@/components/conference/author/ConferenceAuthor.vue'),
                        meta: {
                            admin: true,
                            candidate: true,
                            viceRector: true,
                            reviewer: true
                        },
                    },
                    {
                        path: 'conference-coordinator',
                        name: 'conference-coordinator',
                        component: () => import('@/views/conference/ConferenceModerator.vue'),
                        meta: {
                            admin: true,
                            conferenceModerator: true,
                        },
                    },
                    {
                        path: 'forum-requirements',
                        name: 'forum-requirements',
                        component: () => import('@/views/news/Forum2023.vue'),
                        meta: {
                            admin: true,
                            candidate: true,
                            HRHead: true,
                            departmentHead: true,
                            viceRector: true,
                            commission: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                            shareholder: true,
                        },
                    },
                    {
                        path: 'section-moderator',
                        name: 'section-moderator',
                        component: () => import('@/views/conference/SectionModerator.vue'),
                        meta: {
                            admin: true,
                            sectionModerator: true,
                        },
                    },
                    {
                        path: 'conference-reviewer',
                        name: 'conference-reviewer',
                        component: () => import('@/components/conference/reviewer/ConferenceReviewer.vue'),
                        meta: {
                            admin: true,
                            reviewer: true,
                        },
                    },
                ]
            },
            {
                path: 'teacher',
                name: 'teacher',
                component: () => import('@/views/teacher/Teacher.vue'),
                children: [
                    {
                        path: 'summer',
                        name: 'teacher-summer',
                        component: () => import('@/views/teacher/summer/TeacherSummer.vue'),
                        children: [
                            {
                                path: 'courses',
                                name: 'teacher-summer-courses',
                                component: () => import('@/views/teacher/summer/TeacherSummerCourses.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                            {
                                path: 'grades',
                                name: 'teacher-summer-grades',
                                component: () => import('@/views/teacher/summer/TeacherSummerGrades.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'difference',
                        name: 'teacher-difference',
                        component: () => import('@/views/teacher/difference/TeacherDifference.vue'),
                        children: [
                            {
                                path: 'courses',
                                name: 'teacher-difference-courses',
                                component: () => import('@/views/teacher/difference/TeacherDifferenceCourses.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                            {
                                path: 'grades',
                                name: 'teacher-difference-grades',
                                component: () => import('@/views/teacher/difference/TeacherDifferenceGrades.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'practice',
                        name: 'teacher-practice',
                        component: () => import('@/views/teacher/practice/TeacherPractice.vue'),
                        children: [

                            {
                                path: 'grades',
                                name: 'teacher-practice-grades',
                                component: () => import('@/views/teacher/practice/TeacherPracticeGrades.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'magistracy-prerequisite',
                        name: 'teacher-magistracy-prerequisite',
                        component: () => import('@/views/teacher/magistracy-prerequisite/TeacherMagistracyPrerequisite.vue'),
                        children: [

                            {
                                path: 'grades',
                                name: 'teacher-magistracy-prerequisite-grades',
                                component: () => import('@/views/teacher/magistracy-prerequisite/TeacherMagistracyPrerequisiteGrades.vue'),
                                meta: {
                                    admin: true,
                                    candidate: true,
                                    rector: true,
                                    viceRector: true,
                                    dean: true,
                                    commission: true,
                                    departmentHead: true,
                                },
                            },
                        ]
                    },
                    {
                        path: 'attendance',
                        name: 'teacher-attendance',
                        component: () => import('@/views/teacher/attendance/TeacherAttendance.vue'),
                        meta: {
                            admin: true,
                            candidate: true,
                            rector: true,
                            viceRector: true,
                            dean: true,
                            commission: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'students-attendance-report',
                        name: 'students-attendance-report',
                        meta: {
                            admin: true,
                            candidate: true,
                            rector: true,
                            viceRector: true,
                            dean: true,
                            commission: true,
                            departmentHead: true,
                        },
                        component: () => import('@/views/teacher/attendance/StudentsAttendanceReport.vue')
                    },

                    {
                        path: 'teacher-attendance-journal',
                        name: 'teacher-attendance-journal',
                        component: () => import('@/views/teacher/attendance/TeacherAttendanceJournal.vue'),
                        meta: {
                            admin: true,
                            candidate: true
                        },
                    },
                ]
            },
            {
                path: 'student',
                name: 'student',
                component: () => import('@/views/student/Student.vue'),
                children: [
                    {
                        path: 'summer',
                        name: 'student-summer',
                        component: () => import('@/views/student/summer/StudentSummer.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                    {
                        path: 'practice',
                        name: 'student-practice',
                        component: () => import('@/views/student/practice/StudentPractice.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                    {
                        path: 'difference',
                        name: 'student-difference',
                        component: () => import('@/views/student/difference/StudentDifference.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                    {
                        path: 'teacher-evaluation',
                        name: 'teacher-evaluation',
                        component: () => import('@/views/student/TeacherEvaluation.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                    {
                        path: 'my-profile',
                        name: 'my-profile',
                        component: () => import('@/views/student/MyProfile.vue'),
                        meta: {
                            admin: true,
                            student: true,
                            lawyer: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                            deputyDean: true,
                            dean: true,
                        },
                    },
                    {
                        path: 'choose-disciplines',
                        name: 'choose-disciplines',
                        component: () => import('@/views/student/ChooseDisciplines.vue'),
                        meta: {
                            admin: true,
                            student: true,
                            departmentHead: true,
                            adviser: true
                        },
                    },
                    {
                        path: 'student-attendance-journal',
                        name: 'student-attendance-journal',
                        component: () => import('@/views/student/attendance/StudentAttendanceJournal.vue'),
                        meta: {
                            admin: true,
                            student: true
                        },
                    },
                ]
            },
            {
                path: 'old-discipline-bind-to-new-discipline',
                name: 'old-discipline-bind-to-new-discipline',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/temporary/OldDisciplineBindToNewDiscipline.vue')
            },
            {
                path: 'nis-college-students',
                name: 'nis-college-students',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/temporary/nis-college/NisCollegeStudents.vue')
            },
            {
                path: 'nis-college-ratings/:id',
                name: 'nis-college-ratings',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/temporary/nis-college/NisCollegeRatings.vue')
            },
            {
                path: 'transcript-for-group',
                name: 'transcript-for-group',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/office-registration/TranscriptForGroup.vue')
            },
            {
                path: 'change-discipline-credit',
                name: 'change-discipline-credit',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/office-registration/ChangeDisciplineCreditAndName.vue')
            },
            {
                path: 'statement-print',
                name: 'statement-print',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/office-registration/StatementPrint.vue')
            },
            {
                path: 'statement-course-info',
                name: 'statement-course-info',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/office-registration/StatementCourseInfo.vue')
            },
            {
                path: 'statement-summer-course-info',
                name: 'statement-summer-course-info',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/components/office-registration/StatementSummerCourseInfo.vue')
            },
            {
                path: 'disciplines-difference',
                name: 'disciplines-difference',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/office-registration/disciplines-difference/DisciplinesDifference.vue')
            },
            {
                path: 'education-calendar',
                name: 'education-calendar',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/academic-calendar/EducationCalendar.vue')
            },
            {
                path: 'semester-summer-calendar',
                name: 'semester-summer-calendar',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/academic-calendar/SemesterSummerCalendar.vue')
            },
            {
                path: 'choose-discipline-calendar',
                name: 'choose-discipline-calendar',
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
                component: () => import('@/views/calendar/ChooseDisciplineCalendar.vue')
            },
            {
                path: 'commission-decision',
                name: 'commission-decision',
                meta: {
                    admin: true,
                    commission: true,
                },
                component: () => import('@/views/kkk/commission/CommissionDecision.vue')
            },
            {
                path: 'commission-head-decision',
                name: 'commission-head-decision',
                meta: {
                    admin: true,
                    commissionHead: true,
                },
                component: () => import('@/views/kkk/commission/CommissionHeadDecision.vue')
            },
            {
                path: 'commission-score-new',
                name: 'commission-score-new',
                meta: {
                    admin: true,
                    commission: true,
                    commissionHead: true,
                },
                component: () => import('@/views/kkk/commission/CommissionScore.vue')
            },
            {
                path: 'document',
                name: 'document',
                component: () => import('@/views/document/Document.vue'),
                children: [
                    {
                        path: 'signing-document',
                        name: 'signing-document',
                        component: () => import('@/views/document/signing-document/SigningDocument.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                            dean: true,
                        },
                    },
                ]
            },
            {
                path: 'department',
                name: 'department',
                component: () => import('@/views/department-head/DepartmentHead.vue'),
                children: [
                    {
                        path: 'disciplines-for-teaching-load',
                        name: 'department-head-disciplines-for-teaching-load',
                        component: () => import('@/views/department-head/teaching-load/DisciplinesForTeachingLoad.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'formed-teaching-load',
                        name: 'formed-teaching-load',
                        component: () => import('@/views/department-head/formed-teaching-load/FormedTeachingLoad.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'formed-pps-teaching-load',
                        name: 'formed-pps-teaching-load',
                        component: () => import('@/views/department-head/teaching-load/FormedPpsTeachingLoad.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'office-hours',
                        name: 'department-head-office-hours',
                        component: () => import('@/views/department-head/teaching-load/OfficeHours.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'adviser-choose',
                        name: 'adviser-choose',
                        component: () => import('@/views/department-head/adviser-choose/AdviserChoose.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,

                        },
                    },
                    {
                        path: 'curriculum-optional-component',
                        name: 'curriculum-optional-component',
                        component: () => import('@/views/department-head/curriculum-optional-component/CurriculumOptionalComponent.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'attestation-commission',
                        name: 'attestation-commission',
                        component: () => import('@/views/department-head/attestation-commission/SelectionMembersOfAttestationCommission.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'bind-practice',
                        name: 'bind-practice',
                        component: () => import('@/views/department-head/practice/BindPractice.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'bind-prerequisite',
                        name: 'bind-prerequisite',
                        component: () => import('@/views/department-head/magistracy-prerequisite/BindPrerequisite.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'prerequisites-students',
                        name: 'department-prerequisites-students',
                        component: () => import('@/views/department-head/magistracy-prerequisite/DepartmentPrerequisitesStudents.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'difference-students',
                        name: 'department-difference-students',
                        component: () => import('@/views/department-head/difference/DepartmentDifferenceStudents.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'combine-teaching-load',
                        name: 'combine-teaching-load',
                        component: () => import('@/views/department-head/combined-teaching-load/CombineTeachingLoad.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'publish-courses-to-moodle',
                        name: 'publish-courses-to-moodle',
                        component: () => import('@/views/department-head/combined-teaching-load/PublishCoursesToMoodle.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                            rector: true,
                        },
                    },
                    {
                        path: 'difference',
                        name: 'department-difference',
                        component: () => import('@/views/department-head/difference/DepartmentDifference.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'show-syllabus',
                        name: 'department-show-syllabus',
                        component: () => import('@/views/department-head/show-syllabus/ShowSyllabus.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'pps-teaching-training-direction',
                        name: 'department-pps-teaching-training-direction',
                        component: () =>
                            import('@/views/department-head/pps-teaching-training-direction/PpsTeachingTrainingDirection.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                ]
            },
            {
                path: 'adviser',
                name: 'adviser',
                component: () => import('@/views/adviser/Adviser.vue'),
                children: [
                    {
                        path: 'groups',
                        name: 'adviser-groups',
                        component: () => import('@/views/adviser/groups/AdviserGroups.vue'),
                        meta: {
                            admin: true,
                            adviser: true,
                        },
                    },
                    {
                        path: 'discipline-selection-statistics',
                        name: 'adviser-discipline-selection-statistics',
                        component: () => import('@/views/adviser/groups/DisciplineSelectionStatistics.vue'),
                        meta: {
                            admin: true,
                            adviser: true,
                        },
                    },
                    {
                        path: 'choose-students',
                        name: 'adviser-choose-students',
                        component: () => import('@/views/adviser/choose/AdviserChooseStudents.vue'),
                        meta: {
                            admin: true,
                            adviser: true,
                        },
                    },
                ]
            },
            {
                path: 'office-registration',
                name: 'office-registration',
                component: () => import('@/views/office-registration/OfficeRegistration.vue'),
                children: [
                    {
                        path: 'disciplines-of-foreign-universities',
                        name: 'office-registration-disciplines-of-foreign-universities',
                        component: () => import('@/views/office-registration/foreign-universities/DisciplinesOfForeignUniversities.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'binding-to-disciplines-of-foreign-universities',
                        name: 'office-registration-binding-to-disciplines-of-foreign-universities',
                        component: () => import('@/views/office-registration/foreign-universities/BindingToDisciplinesOfForeignUniversities.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'grades-of-foreign-disciplines/:id',
                        name: 'office-registration-grades-of-foreign-disciplines',
                        component: () => import('@/views/office-registration/foreign-universities/GradesOfForeignDisciplines.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'old-ratings',
                        name: 'office-registration-old-ratings',
                        component: () => import('@/views/office-registration/ratings/OldRatings.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'student-practice-grades',
                        name: 'office-registration-student-practice-grades',
                        component: () => import('@/views/office-registration/practice/StudentPracticeGrades.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'prerequisites-magistracy',
                        name: 'office-registration-prerequisites-magistracy',
                        component: () => import('@/views/office-registration/prerequisites/PrerequisitesMagistracy.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'difference',
                        name: 'office-registration-difference',
                        component: () => import('@/views/office-registration/difference/OfficeRegistrationStudentDifference.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'student-difference-request',
                        name: 'office-registration-student-request',
                        component: () => import('@/views/office-registration/difference/OfficeRegistrationStudentRequest.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'open-vsk',
                        name: 'office-registration-open-vsk',
                        component: () => import('@/views/office-registration/discipline-opening/DisciplineOpening.vue'),
                        meta: {
                            admin: true,
                            officeRegistrationHead: true,
                        },
                    },
                    {
                        path: 'email-change',
                        name: 'office-registration-email-change',
                        component: () => import('@/views/office-registration/email-change/EmailChange.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                        },
                    },
                    {
                        path: 'transcript-orders',
                        name: 'transcript-orders',
                        component: () => import('@/views/office-registration/transcript-orders/TranscriptOrders.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                        },
                    },
                ]
            },

            {
                path: 'kkk',
                name: 'kkk',
                component: () => import('@/views/kkk/KKK.vue'),
                children: [
                    {
                        path: 'department-head-vacancy-applications',
                        name: 'department-head-vacancy-applications',
                        component: () => import('@/views/kkk/department-head/DepartmentHeadVacancyApplications.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'teachers',
                        name: 'hr-teachers',
                        component: () => import('@/views/kkk/hr/HRTeachers.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                            rector: true,
                        },
                    },
                    {
                        path: 'teachers-orders',
                        name: 'teachers-orders',
                        component: () => import('@/views/kkk/hr/TeachersOrders.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                            rector: true,
                        },
                    },
                    {
                        path: 'teachers-orders-members',
                        name: 'teachers-orders-members',
                        component: () => import('@/views/kkk/hr/TeachersOrdersMembers.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                            rector: true,
                        },
                    },
                    {
                        path: 'teachers-contracts',
                        name: 'teachers-contracts',
                        component: () => import('@/views/kkk/hr/TeachersContracts.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                            rector: true,
                        },
                    },
                    {
                        path: 'hr-vacancy-applications',
                        name: 'hr-vacancy-applications',
                        component: () => import('@/views/kkk/hr/HRVacancyApplications.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                        },
                    },
                    {
                        path: 'hr-score',
                        name: 'hr-score',
                        component: () => import('@/views/kkk/hr/HRScore.vue'),
                        meta: {
                            admin: true,
                            HRHead: true,
                        },
                    },
                ]
            },


            {
                path: 'schedule',
                name: 'schedule',
                component: () => import('@/views/schedule/Schedule.vue'),
                children: [
                    {
                        path: 'manager',
                        name: 'schedule-manager',
                        component: () => import('@/views/schedule/manager/ManagerSchedule.vue'),
                        meta: {
                            admin: true,
                            scheduleManager: true,
                            officeRegistration: true,
                            officeRegistrationHead: true,
                            rector: true
                        },
                    },
                    {
                        path: 'full',
                        name: 'schedule-full',
                        component: () => import('@/views/schedule/manager/FullSchedule.vue'),
                        meta: {
                            admin: true,
                            scheduleManager: true,
                            officeRegistration: true,
                            officeRegistrationHead: true
                        },
                    },
                    {
                        path: 'user/:id',
                        name: 'schedule-user',
                        component: () => import('@/views/schedule/user-schedule/UserSchedule.vue'),
                        meta: {
                            admin: true,
                            scheduleManager: true,
                            officeRegistration: true
                        },
                    },
                    {
                        path: 'teacher',
                        name: 'schedule-teacher',
                        component: () => import('@/views/schedule/teacher/TeacherSchedule.vue'),
                        meta: {
                            admin: true,
                            candidate: true,
                            rector: true,
                            viceRector: true,
                            dean: true,
                            commission: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'my',
                        name: 'schedule-my',
                        component: () => import('@/views/schedule/my-schedule/MySchedule.vue'),
                        meta: {
                            admin: true,
                            candidate: true,
                            rector: true,
                            viceRector: true,
                            dean: true,
                            commission: true,
                            departmentHead: true,
                            student: true,
                        },
                    },
                    {
                        path: 'student',
                        name: 'schedule-student',
                        component: () => import('@/views/schedule/student/StudentSchedule.vue'),
                        meta: {
                            admin: true,
                            adviser: true,
                            student: true,
                        },
                    },
                ]
            },

            {
                path: 'lesson-visiting/:id/time/:time',
                name: 'lesson-visiting',
                meta: {
                    admin: true,
                    student: true,
                },
                component: () => import('@/views/student/LessonVisiting.vue')
            },


            {
                path: 'teaching-load',
                name: 'teaching-load',
                component: () => import('@/views/teaching-load/TeachingLoad.vue'),
                children: [
                    {
                        path: 'disciplines',
                        name: 'teaching-load-disciplines',
                        component: () => import('@/views/teaching-load/disciplines/TeachingLoadDisciplines.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'language-disciplines',
                        name: 'teaching-load-language-disciplines',
                        component: () => import('@/views/teaching-load/disciplines/TeachingLoadLanguageDisciplines.vue'),
                        meta: {
                            admin: true,
                            departmentHead: true,
                        },
                    },
                    {
                        path: 'bind-students-to-kazakh-discipline',
                        name: 'teaching-load-bind-students-to-kazakh-discipline',
                        component: () => import('@/views/teaching-load/language-center/BindStudentsToKazakhDiscipline.vue'),
                        meta: {
                            admin: true,
                            officeRegistration: true,
                            officeRegistrationHead: true
                        },
                    },
                ]
            },
            {
                path: 'difference',
                name: 'difference',
                component: () => import('@/views/difference/Difference.vue'),
                children: [
                    {
                        path: 'student-application',
                        name: 'difference-student-application',
                        component: () => import('@/views/difference/student/DifferenceStudentApplication.vue'),
                        meta: {
                            admin: true,
                            student: true,
                        },
                    },
                ]
            },
            {
                path: 'student-research-cup-dean',
                name: 'student-research-cup-dean',
                component: () => import('@/views/student-research-cup/CupDean.vue'),
                meta: {
                    admin: true,
                    dean: true,
                },
            },
            {
                path: 'research-cup',
                name: 'research-cup',
                component: () => import('@/views/student-research-cup/ResearchCup.vue'),
                children: [
                    {
                        path: 'registered-students-list',
                        name: 'registered-students-list',
                        component: () => import('@/views/student-research-cup/deputy-dean/RegisteredStudentsList.vue'),
                        meta: {
                            admin: true,
                            deputyDean: true
                        },
                    },
                    {
                        path: 'steps-score',
                        name: 'steps-score',
                        component: () => import('@/views/student-research-cup/commission/StepsScore.vue'),
                        meta: {
                            admin: true,
                            deputyDean: true,
                            chairmanResearchCup: true,
                            commissionResearchCup: true,
                        },
                    },
                    {
                        path: 'research-cup-registration',
                        name: 'research-cup-registration',
                        component: () => import('@/views/student-research-cup/student/ResearchCupRegistration.vue'),
                        meta: {
                            admin: true,
                            student: true
                        },
                    }
                ]
            },

            {
                path: 'semester-calendar',
                name: 'semester-calendar',
                component: () => import('@/views/calendar/SemesterCalendar.vue'),
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
            },
            {
                path: 'holiday-calendar',
                name: 'holiday-calendar',
                component: () => import('@/views/calendar/HolidayCalendar.vue'),
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
            },
            {
                path: 'choose-discipline-calendar',
                name: 'choose-discipline-calendar',
                component: () => import('@/views/calendar/ChooseDisciplineCalendar.vue'),
                meta: {
                    admin: true,
                    officeRegistration: true,
                    officeRegistrationHead: true
                },
            },
            {
                path: 'change-photo-pps',
                name: 'change-photo-pps',
                component: () => import('@/views/pps/PpsChangePhoto.vue'),
                meta: {
                    admin: true,
                    marketer: true,
                    assistant: true
                },
            },
            {
                path: 'change-photo-administration',
                name: 'change-photo-administration',
                component: () => import('@/views/pps/AdministrationChangePhoto.vue'),
                meta: {
                    admin: true,
                    marketer: true,
                    assistant: true
                },
            },
            {
                path: 'kpi-teacher',
                name: 'kpi-teacher',
                component: () => import('@/views/kpi/KpiTeacher.vue'),
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    viceRector: true,
                    commission: true,
                },
            },
            {
                path: 'kpi-commission',
                name: 'kpi-commission',
                component: () => import('@/views/kpi/KpiCommission.vue'),
                meta: {
                    admin: true,
                    kpiCommission: true
                },
            },
            {
                path: 'kpi-commission-teacher',
                name: 'kpi-commission-teacher',
                component: () => import('@/views/kpi/KpiCommissionTeacher.vue'),
                meta: {
                    admin: true,
                    departmentHead: true,
                    kpiCommission: true,
                },
            },
            {
                path: 'kpi-teachers-department-head',
                name: 'kpi-teachers-department-head',
                component: () => import('@/views/kpi/KpiTeachersDepartmentHead.vue'),
                meta: {
                    admin: true,
                    departmentHead: true,
                },
            },
            {
                path: 'organizations',
                name: 'organizations',
                component: () => import('@/views/organization/Organizations.vue'),
                meta: {
                    admin: true,
                    youthCenter: true
                },
            },
            // {
            //     path: 'users',
            //     name: 'users',
            //     component: () => import('@/views/admin/Users.vue'),
            //     meta: {
            //         admin: true,
            //     },
            // },
            {
                path: 'ncalayer',
                name: 'ncalayer',
                component: () => import('@/views/ncalayer/NCALayer.vue'),
                meta: {
                    admin: true,
                    dean: true,
                    departmentHead: true,
                    qualityCenterMain: true,
                },
            },
            {
                path: 'organization-joining',
                name: 'organization-joining',
                component: () => import('@/views/organization/OrganizationJoining.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'survey-students',
                name: 'survey-students',
                component: () => import('@/views/survey/SurveyStudents.vue'),
                meta: {
                    admin: true,
                    candidate: true
                },
            },
            {
                path: 'events-list',
                name: 'events-list',
                component: () => import('@/views/events/EventsList.vue'),
                meta: {
                    admin: true,
                    student: true,
                },
            },
            {
                path: 'student-event-registration',
                name: 'student-event-registration',
                component: () => import('@/views/events/StudentEventRegistration.vue'),
                meta: {
                    admin: true,
                },
            },
            {
                path: 'dispatcher-events',
                name: 'dispatcher-events',
                component: () => import('@/views/events/DispatcherEvents.vue'),
                meta: {
                    admin: true,
                },
            },
            {
                path: 'security-events',
                name: 'security-events',
                component: () => import('@/views/events/SecurityEvents.vue'),
                meta: {
                    admin: true,
                },
            },
            {
                path: 'dmp-events',
                name: 'dmp-events',
                component: () => import('@/views/events/DmpEvents.vue'),
                meta: {
                    admin: true,
                    youthCenter: true,
                },
            },
            {
                path: 'survey',
                name: 'survey',
                component: () => import('@/views/sei/Survey.vue'),
                meta: {
                    admin: true,
                    candidate: true
                },
            },
            {
                path: 'big-five',
                name: 'big-five',
                component: () => import('@/views/big-five/BigFive.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'creative-imagination-test',
                name: 'creative-imagination-test',
                component: () => import('@/views/sei/CreativeImaginationTest.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'eqi-short',
                name: 'eqi-short',
                component: () => import('@/views/eqi-short/EqiShort.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'luscher',
                name: 'luscher',
                component: () => import('@/views/luscher/Luscher.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'organization-members',
                name: 'organization-members',
                component: () => import('@/views/organization/OrganizationMembers.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'social-gpa',
                name: 'social-gpa',
                component: () => import('@/views/social-gpa/SocialGpa.vue'),
                meta: {
                    admin: true,
                    student: true
                },
            },
            {
                path: 'education-programme-supervisor',
                name: 'education-programme-supervisor',
                component: () => import('@/views/education-programme/EducationProgrammeSupervisor.vue'),
                meta: {
                    admin: true,
                    educationprogramSupervisor: true
                },
            },
            {
                path: 'conference/sagadiyev2023/program.pdf',
                name: 'program',
                component: () => import('@/views/conference-files/Program.vue'),
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
            },
            {
                path: 'conference/sagadiyev2023/proceeding.pdf',
                name: 'proceeding',
                component: () => import('@/views/conference-files/Proceeding.vue'),
                meta: {
                    admin: true,
                    candidate: true,
                    HRHead: true,
                    departmentHead: true,
                    commission: true,
                    viceRector: true,
                },
            },
            {
                path: 'minor-education-program',
                name: 'minor-education-program',
                component: () => import('@/views/education-programme/MinorEducationProgram.vue'),
                meta: {
                    admin: true,
                    departmentHead: true,
                    educationprogramSupervisor: true
                },
            },
            {
                path: 'minor-education-program-confirm',
                name: 'minor-education-program-confirm',
                component: () => import('@/components/minor-education-program/MinorEducationProgramConfirm.vue'),
                meta: {
                    admin: true,
                    departmentHead: true,
                },
            },
            {
                path: 'education-program-minor-disciplines',
                name: 'education-program-minor-disciplines',
                component: () => import('@/views/education-programme/EducationProgramMinorDisciplines.vue'),
                meta: {
                    admin: true,
                    departmentHead: true,
                    educationprogramSupervisor: true
                },
            },
            {
                path: 'student-orders',
                name: 'student-orders',
                component: () => import('@/views/student-orders/StudentOrders.vue'),
                meta: {
                    admin: true,
                    dean: true,
                    rector: true
                },
            },
            {
                path: 'diploma-reference',
                name: 'diploma-reference',
                component: () => import('@/views/diploma-reference/DiplomaReference.vue'),
                meta: {
                    admin: true,
                    dean: true
                },
            },
            {
                path: 'reference-logs',
                name: 'reference-logs',
                component: () => import('@/views/diploma-reference/ReferenceLogs.vue'),
                meta: {
                    admin: true,
                    dean: true
                },
            },
            {
                path: '/winter-science-school-qr',
                name: 'winter-science-school-qr',
                component: () => import('@/views/winter-science-school/QRWinterScienceSchool.vue'),
                meta: {
                    admin: true,
                    winterScienceSchoolAdmin: true
                },
            },
            {
                path: '/winter-science-school-attendance-list',
                name: 'winter-science-school-attendance-list',
                component: () => import('@/views/winter-science-school/AttendanceListWinterScienceSchool.vue'),
                meta: {
                    admin: true,
                    winterScienceSchoolAdmin: true
                },
            },
            {
                path: '/winter-science-school-certificate-holders-list',
                name: 'winter-science-school-certificate-holders-list',
                component: () => import('@/views/winter-science-school/ListCertificateHoldersWinterScienceSchool.vue'),
                meta: {
                    admin: true,
                    designer: true,
                    winterScienceSchoolAdmin: true
                },
            },
            {
                path: '/predict-list',
                name: 'predict-list',
                component: () => import('@/views/predict/PredictList.vue'),
                meta: {
                    admin: true,
                    rector: true,
                    dean: true,
                    departmentHead: true,
                },
            },
        ]
    },
    {
        path: '/auth',
        name: 'Auth',
        component: () => import('@/layouts/AuthLayout.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/Login.vue'),
            },
            {
                path: 'register',
                name: 'register',
                meta: {
                    admin: true,
                    candidate: true,
                    dean: true,
                    enrollee: true
                },
                component: () => import('@/views/Registration.vue'),
            },
            {
                path: 'register-wto',
                name: 'register-wto',
                component: () => import('@/views/RegistrationWto.vue'),
            },
            {
                path: 'recovery',
                name: 'recovery',
                component: () => import('@/views/auth/Recovery.vue'),
            },
        ]
    },
    {
        path: '/winter-visiting/:id/',
        name: 'winter-visiting',
        component: () => import('@/views/winter-science-school/AttendanceWinterScienceSchool.vue'),
    },
    {
        path: '/qr-verify-winter-science-school/:id',
        name: 'qr-verify-winter-science-school',
        component: () => import('@/views/winter-science-school/CertificateHoldersConfirmationWSS.vue'),
    },
    {
        path: '/qr-verify',
        name: 'qr-verify',
        component: () => import('@/views/qr-verify/QrVerify.vue'),
    },
    {
        path: '/login',
        redirect: '/auth/login'
    },
    {
        path: '/register',
        redirect: '/auth/register'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import( '@/views/NotFound.vue'),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

const pagesWithoutAuthorization = ['login', 'register', 'recovery', 'register-wto', 'winter-visiting', 'qr-verify', 'qr-verify-winter-science-school']
const pagesForEveryoneWithAuthorization = ['restrict', 'notFound']

router.beforeEach(async (to) => {
    console.log(to)

    if (pagesWithoutAuthorization.includes(to.name)) {
        return true
    }

    if (!authService.isLoggedIn()) {
        let query = {}
        if (to.name === 'lesson-visiting') {
            query = {redirect: window.location.href}
        }
        return {name: 'login', query: query}
    }

    if (pagesForEveryoneWithAuthorization.includes(to.name)) {
        return true
    }

    // const roles = JSON.parse(getCookie('ROLES')) || []
    // if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    // }

    let roles = getDecryptedRoles()

    // if (decryptCheckRole('student')) {
    //     if (to.name !== 'choose-disciplines') {
    // //         const {data} = await httpClient.get('education_program/education-discipline-choose/check-student-choose')
    // //         if (!data) {
    // //             return {name: 'choose-disciplines', query: {notification: 'Выберите дисциплины ИУП!'}}
    // //         }
    // //
    //         if (to.name !== 'student-attestation' && to.name !== 'home' && to.name !== 'teacher-evaluation') {
    //             const {data} = await httpClient.get('student/attestation/get-current-attestation?barcode=' + getCookie('USERNAME'))
    //             for (let datum of data.success) {
    //                 if (datum.pps_id_lecture.evaluate_status === 0 || datum.pps_id_seminar.evaluate_status === 0) {
    //                     //return {name: 'student-attestation', query: {notification: 'Пройдите анкетирование!!!'}}
    //                     return {name: 'student-attestation'}
    //                 }
    //             }
    //         }
    //     }
    // }

    if (to.name === 'home' && decryptCheckRole('enrollee')) {
        return +getCookie('PASSWORD_CHANGED') === 0 ? {name: 'change-password'} : {name: 'enrollee-eds'}
    }
    if (to.name === 'enrollee-eds' && decryptCheckRole('enrollee') && +getCookie('PASSWORD_CHANGED') === 0) {
        return {name: 'change-password'}
    }
    if (to.name === 'home' && decryptCheckRole('dean')) {
        return {name: 'dean-eds'}
    }
    if (to.name === 'home' && (decryptCheckRole('tehSecretary') || decryptCheckRole('studentSponsor'))) {
        return {name: 'main'}
    }


    if (roles.some(r => to.meta[r])) {
        return true
    }

    return (!to.name) ? {name: 'notFound'} : {name: 'restrict'}

})

export default router
